<template>
  <div class="content-wrapper">
    <div class="d-flex justify-content-between">
      <div>
        <h1 style="margin: 0">Ordini di servizio</h1>
        <h3 v-if="dossier" style="margin-top: 0">
          {{ dossier.codice ? dossier.codice : "" }} Dossier
          {{ dossier.titolo ? dossier.titolo : "" }} -
          {{ dossier.Company ? dossier.Company : "" }}
        </h3>
      </div>
      <div style="font-size: 1.5rem">
        <b-badge variant="warning">
          <b-icon-star-fill class="mr-2"></b-icon-star-fill> Plugin
        </b-badge>
      </div>
    </div>
    <b-card class="mb-4">
      <b-row class="my-1 justify-content-between">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <b-button @click="$router.go(-1)"
            ><b-icon icon="chevron-left" aria-hidden="true"></b-icon> Torna ai
            Dossier</b-button
          >

          <div class="d-flex justify-content-end align-items-center">
            <b-button
              size="sm"
              class="mb-2 mr-2"
              @click="$router.push('/axerta/timeline')"
              >Vai a calendario</b-button
            >
            <b-button
              size="sm"
              class="mb-2"
              v-b-toggle.sidebar-ordine-di-servizio
            >
              <b-icon icon="plus" aria-hidden="true"></b-icon> Nuovo Ordine di
              servizio
            </b-button>
          </div>
        </div>
      </b-row>
    </b-card>
    <b-overlay :show="items == null" rounded="sm" class="overlay-placeholder">
      <b-card>
        <div v-if="loading">
          <b-skeleton></b-skeleton>
        </div>
        <b-table
          v-else
          hover
          :items="formattedItems"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <template #cell(luogo)="row">
            <span v-if="row.item.luogo.includes('goo.gl/maps')">
              <a :href="row.item.luogo" target="blank">Apri sulla mappa</a>
            </span>
            <span v-else>{{ row.item.luogo }}</span>
          </template>
          <template #cell(dataOra_inizio)="row">
            {{ new Date(row.item.dataOra_inizio).toLocaleDateString("it") }}
          </template>
          <template #cell(confermato)="row">
            <span style="font-size: 1.5rem">
              <b-icon-check
                v-if="row.item.confermato"
                class="ml-4"
              ></b-icon-check>
              <b-icon-x v-else class="ml-4"></b-icon-x>
            </span>
          </template>
          <template #cell(elementi)="row">
            {{ row.item.elementi.length }}
          </template>
          <template #cell(azioni)="row">
            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <b-icon-gear-fill></b-icon-gear-fill>
              </template>

              <b-dropdown-item
                href="#"
                v-b-toggle.sidebar-ordine-di-servizio
                @click="
                  currentItem = row.item;
                  tmpItem = JSON.parse(JSON.stringify(row.item));
                "
                >Modifica</b-dropdown-item
              >
              <b-dropdown-item @click="duplica(row.item)" href="#"
                >Duplica</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                @click="
                  tmpItem = row.item;
                  showDeleteModal = true;
                "
                >Elimina</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="mt-4 mb-1"
        ></b-pagination>
      </b-card>
    </b-overlay>

    <b-sidebar
      v-if="dossier"
      class="right-sidebar"
      id="sidebar-ordine-di-servizio"
      right
      shadow
      lazy
      @hidden="resetData"
      @shown="showSidebar"
      :visible="sidebarOrdineVisible"
      style="z-index:901 !important"
    >
      <div class="content-wrapper my-3 mx-1">
        <div
          v-if="tmpItem && tmpItem.id"
          class="d-flex justify-content-between"
        >
          <h4>
            {{ tmpItem.titolo || tmpItem.codice }}
          </h4>
          <div style="color: gray" class="d-flex flex-column">
            <span> Dossier: {{ dossier.codice }} </span>
            <span> {{ dossier.titolo }} - {{ dossier.Company }} </span>
          </div>
        </div>
        <h4 v-else>Nuovo ordine di servizio</h4>

        <b-row class="mb-4">
          <b-col>
            <b-form-checkbox v-model="tmpItem.confermato">
              Confermato
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row class="mb-2">
          <!-- <b-col>
            <b-form-group label="Titolo">
              <b-form-input v-model="tmpItem.titolo" />
            </b-form-group>
          </b-col> -->
        </b-row>

        <b-row>
          <b-col>
            <!-- <b-form-input v-model="tmpItem.luogo" /> -->
            <label>Luogo</label>
            <CustomGmapAutocomplete
              :placeholder="tmpItem.luogo"
              @gmap-autocomplete::place-changed="handlePlaceChanged"
            ></CustomGmapAutocomplete>
          </b-col>
          <b-col>
            <b-form-group label="Link Maps">
              <b-form-input v-model="tmpItem.linkMaps" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mb-4">
          <b-col>
            <label class="mr-2">CIE Assegnato</label>
            <!-- <b-form-select
              v-model="tmpItem.idCie"
              :options="CieOptions"
              @change="selectCie"
            >
            </b-form-select> -->
            <b-form-select v-model="tmpItem.idCie" :options="CieOptions">
            </b-form-select>
          </b-col>
        </b-row>

        <!-- <b-row class="mb-2">
          <b-col>
            <b-form-group label="Indagato">
              <b-form-input v-model="tmpItem.Indagato" />
            </b-form-group>
          </b-col>
        </b-row> -->

        <b-row>
          <b-col>
            <b-form-group label="Data Ora suggerita">
              <b-row>
                <b-col>
                  <b-form-datepicker
                    v-model="tmpItem.dataOra_inizio"
                    :min="new Date(dossier.dataInizio)"
                    :max="new Date(dossier.dataFine)"
                    locale="it"
                  ></b-form-datepicker>
                </b-col>

                <b-col>
                  <vue-timepicker
                    class="custom-timepicker"
                    advanced-keyboard
                    manual-input
                    v-model="tmpItem.ora_suggerita"
                  ></vue-timepicker>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Durata Stimata (giorni)">
              <b-form-input type="number" v-model="tmpItem.durataStimata" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="tmpItem.elementi">
          <b-col>
            <b-form-group
              label="Allegati in anagrafica dossier da aggiungere all'ordine di servizio"
            >
              <TagsInput
                v-model="elementiSelezionati"
                :tagsList="dossier.elementi"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="dossier.indagatiOptions">
          <b-col>
            <b-form-group label="Indagati">
              <TagsInput
                v-model="indagatiSelezionati"
                :tagsList="dossier.indagatiOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Descrizione Ordine di Servizio">
              <VueEditor class="editor" v-model="tmpItem.annotazioni" />
              <!-- <b-form-textarea
                v-model="tmpItem.annotazioni"
                rows="3"
                max-rows="6"
              ></b-form-textarea> -->
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Numero CIE assegnati">
              <b-form-input type="number" v-model="tmpItem.numeroCie" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="justify-content-start align-items-center">
          <b-col>
            <b-form-group
              label="File allegato specifico per l'ordine di servizio"
            >
              <b-form-file
                class="button-file attachment"
                v-model="tmpAllegato"
                :state="Boolean(tmpAllegato)"
                :disabled="loadingFile"
                @input="uploadFileAllegato"
              ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="
              tmpItem.allegato &&
                tmpItem.allegato.includes('https://api-axerta.whappy.it/Drive/')
            "
          >
            <b-button @click="downloadFile">
              <b-icon-download></b-icon-download>
              Download file allegato specifico per l'ordine di servizio
            </b-button>
          </b-col>
        </b-row>

        <div class="mt-5 mb-5 ">
          <!-- <b-button v-if="tmpItem.confermato" disabled squared variant="outline-primary" size="lg" class="big-button">
            <b-icon-check></b-icon-check> Confermato
          </b-button> -->
          <b-button
            squared
            :disabled="loading || loadingFile || !validOrdineDiServizio"
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveItem"
          >
            {{ !loading ? "Salva" : "Loading..." }}</b-button
          >
        </div>
      </div>
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attenzione!"
      ok-title="Conferma"
      cancel-title="Annulla"
      @hidden="resetData"
      @ok="deleteItem(tmpItem.id)"
    >
      Sei sicuro di voler eliminare l'ordine di servizio
      {{ tmpItem.titolo || tmpItem.id }}?</b-modal
    >
  </div>
</template>

<script>
import AxiosService from "../../axiosServices/AxiosService";
import CustomGmapAutocomplete from "../../components/CustomGmapAutocomplete";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
    CustomGmapAutocomplete,
  },
  created() {
    if (this.tmpItem?.titolo.length == 0 || !this.tmpItem?.titolo) {
      this.tmpItem.titolo =
        "ODI del " +
        new Date().toLocaleDateString("it", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        });
    }
  },
  async mounted() {
    this.idDossier = this.$route.params.idDossier;
    this.getDossierData();
    await this.getData();
    this.getUsers();
  },
  data() {
    return {
      tmpAllegato: null,
      elementiSelezionati: [],
      indagatiSelezionati: [],
      currentPage: 1,
      perPage: 10,
      filter: "",
      idDossier: null,
      currentItem: null,
      loading: false,
      tmpItem: {
        id: null,
        titolo: "",
        annotazioni: "",
        Indagato: "",
        codice: "",
        confermato: "",
        dataOra_inizio: "",
        elementi: [],
        idCie: "",
        idDossier: "",
        luogo: "",
        linkMaps: "",
        numeroCie: 1,
        allegato: null,
        indagati: [],
      },
      loadingFile: false,
      fields: [
        // { key: "codice", label: "Codice" },
        // { key: "id", label: "ID" },
        // { key: "titolo", label: "Titolo" },
        { key: "luogo", label: "Luogo" },
        { key: "Cie", label: "CIE" },
        { key: "dataOra_inizio", label: "Inizio" },
        // { key: 'idCie', label: 'idCie' },
        // { key: 'idDossier', label: 'idDossier' },
        { key: "numeroCie", label: "N. CIE" },
        { key: "rilevazioni", label: "N. Rilevazioni" },
        { key: "durataStimata", label: "Durata Stimata" },
        { key: "confermato", label: "Confermato" },
        { key: "azioni", label: "Azioni" },
      ],
      items: [],
      sidebarOrdineVisible: false,
      showDeleteModal: false,
      CieOptions: [],
      dossier: null,
    };
  },
  methods: {
    uploadFileAllegato() {
      this.loadingFile = true;
      const service = new AxiosService();
      const formData = new FormData();

      // if (this.tmpAllegato.size > 3 * 1024 * 1024) {
      //   alert("File troppo grande (3MB)");
      //   return;
      // }

      formData.append("file", this.tmpAllegato);
      service
        .uploadFile(formData)
        .then((res) => {
          this.tmpItem.allegato =
            "https://api-axerta.whappy.it/Drive/" + res.data;
        })
        .finally(() => {
          this.loadingFile = false;
        });
    },
    downloadFile() {
      const link = document.createElement("a");
      link.href = this.tmpItem.allegato;
      link.setAttribute("download", this.tmpItem.allegato);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handlePlaceChanged(e) {
      console.log(e);
      this.tmpItem.luogo = e.formatted_address;
      this.tmpItem.linkMaps = e.url;
      this.tmpItem.geo = e.geometry.location.toJSON();
    },
    showSidebar() {
      this.tmpItem.elementi.forEach((element) => {
        this.elementiSelezionati.push(element.id);
      });

      this.tmpItem.indagati.forEach((element) => {
        this.indagatiSelezionati.push(element);
      });

      this.tmpItem.ora_suggerita = this.getTimeFromString(
        this.tmpItem.dataOra_inizio
      );
      this.sidebarOrdineVisible = true;
    },
    getDossierData() {
      const service = new AxiosService(
        "DossierManager/GetDossier/" + this.idDossier
      );
      service.read().then((res) => {
        res.indagatiOptions = [];
        res.elementi.forEach((elemento) => {
          if (elemento) {
            elemento.text = elemento.tag;
            elemento.value = elemento.id;
          }
        });

        res.indagati.forEach((elemento) => {
          console.log("elemento INDAGATO", elemento);
          if (elemento && elemento.length > 0) {
            let temIndagato = {
              text: elemento,
              tag: elemento,
              value: elemento,
            };
            res.indagatiOptions = [...res.indagatiOptions, temIndagato];
          }
        });
        this.dossier = res;
      });
    },
    async getData() {
      this.loading = true;
      this.items.splice(0);
      const service = new AxiosService(
        "DossierManager/GetOrdiniDiServizio/" + this.idDossier
      );
      service
        .read()
        .then((res) => {
          for (let item of res) {
            item.indagatiOptions = [];

            for (let indagato of item.indagati) {
              if (indagato.length == 0) {
                item.indagati.splice(item.indagati.indexOf(indagato), 1);
              } else {
                let temIndagato = {
                  text: indagato,
                  value: indagato,
                };
                item.indagatiOptions = [...item.indagatiOptions, temIndagato];
              }
            }

            this.addItemToItems(item);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addItemToItems(item) {
      this.items.push(item);
    },
    resetData() {
      this.tmpItem = {
        id: null,
        titolo: "",
        annotazioni: "",
        codice: false,
        confermato: "",
        dataOra_inizio: "",
        elementi: [],
        idCie: "",
        idDossier: "",
        luogo: "",
        numeroCie: 1,
        allegato: null,
        indagati: [],
      };
      this.elementiSelezionati.splice(0);
      this.indagatiSelezionati.splice(0);
    },
    getTimeFromString(timeString) {
      const date = new Date(timeString);
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return {
        HH: hours,
        mm: minutes,
      };
    },
    async saveItem() {
      this.loading = true;

      let nuoviSelezionati = [];
      let nuoviIndagatiSelezionati = [];

      let dateAndTime = this.tmpItem.dataOra_inizio.split("T");
      let newTime = `${this.tmpItem.ora_suggerita.HH}:${this.tmpItem.ora_suggerita.mm}:00`;

      this.tmpItem.titolo = new Date().toLocaleDateString("it", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });

      if (dateAndTime.length > 1) {
        this.tmpItem.dataOra_inizio = dateAndTime[0] + "T" + newTime;
      } else {
        this.tmpItem.dataOra_inizio =
          this.tmpItem.dataOra_inizio + "T" + newTime;
      }

      this.elementiSelezionati.forEach((selezionato) => {
        this.dossier.elementi.forEach((elemento) => {
          if (elemento.id == selezionato) {
            nuoviSelezionati.push({
              id: elemento.id,
              tag: elemento.tag,
              descrizione: elemento.descrizione,
              allegati: elemento.allegati,
              delete: false,
            });
          }
        });

        this.tmpItem.elementi = nuoviSelezionati;
      });

      this.indagatiSelezionati.forEach((selezionato) => {
        this.dossier.indagatiOptions.forEach((elemento) => {
          if (elemento.value == selezionato) {
            nuoviIndagatiSelezionati.push(elemento.text);
          }
        });

        this.tmpItem.indagati =
          nuoviIndagatiSelezionati.length > 0 ? nuoviIndagatiSelezionati : [];
      });

      this.tmpItem.durataStimata = Number(this.tmpItem.durataStimata);

      if (this.tmpItem.id) {
        console.log("PUT", this.tmpItem);
        const service = new AxiosService("DossierManager/EditOrdineDiServizio");

        this.tmpItem.elementi;
        service
          .update(this.tmpItem)
          .then((res) => console.log(res))
          .finally(async () => {
            this.sidebarOrdineVisible = false;
            await this.getData();
            this.loading = false;
          });
      } else {
        console.log("POST");
        const service = new AxiosService(
          "DossierManager/AddOrdineDiServizio/" + this.$route.params.idDossier
        );
        service
          .create(this.tmpItem)
          .then((res) => console.log(res))
          .finally(async () => {
            this.sidebarOrdineVisible = false;
            await this.getData();
            this.loading = false;
          });
      }
    },

    duplica(item) {
      this.loading = true;
      const service = new AxiosService(
        "DossierManager/DuplicateOrdineDiServizio/" + item.id
      );
      service
        .create(item)
        .then((res) => console.log(res))
        .finally(async () => {
          this.sidebarOrdineVisible = false;
          await this.getData();
          this.loading = false;
        });
    },
    deleteItem(id) {
      this.loading = true;
      const service = new AxiosService("DossierManager/DeleteOrdineDiServizio");
      service
        .delete(id)
        .then((res) => console.log(res))
        .finally(async () => {
          this.showDeleteModal = false;
          await this.getData();
          this.loading = false;
        });
    },
    getUsers() {
      const service = new AxiosService("Option/User");
      service.read().then((res) => {
        this.CieOptions = res;
      });
    },
    // selectCie(item) {
    //   if (!item) return;
    //   this.tmpItem.Cie = this.tempItem.idCie;
    //   this.tmpItem.idCie = this.CieOptions.filter((x) => {
    //     return x.id == this.tmpItem.idCie;
    //   });
    // },
    getVariant(confermato) {
      switch (confermato) {
        case true:
          return "success";
        case false:
          return "";
        default:
          return "";
      }
    },
  },
  computed: {
    validOrdineDiServizio() {
      if (this.tmpItem.idCie > 0) {
        return true;
      } else {
        return false;
      }
    },
    totalRows() {
      return this.items ? this.items.length : 0;
    },
    formattedItems() {
      if (!this.items) return [];
      return this.items.map((item) => {
        item._rowVariant = this.getVariant(item.confermato);
        return item;
      });
    },
    // ...mapGetters(["languages", "default_lang", "config"]),
    //     ...mapGetters(["default_lang"]),
    //     ...mapGetters(["config"]),
  },
};
</script>
<style scoped lang="scss">
.editor {
  background-color: #fff;
  .ql-editor {
    min-height: 600px;
  }
  .ql-snow {
    &.ql-toolbar,
    &.ql-container {
      border: 1px solid #b6b6b6;
    }
  }
}
</style>
